<template>
  <section class="wrapper__business-woocommerce-add px-4">
    <b-row>
      <b-col xs="12" lg="12">
        <h4 class="h4 font-semi-bold mb-4">
          Integración con Payphone
        </h4>
        <el-form :model="form" :rules="rules" ref="form">
          <b-row>
            <b-col xs="12" sm="12" md="12">
              <el-form-item prop="token" class="mb-4">
                <slot name="label">
                  <label class="font-semi-bold m-0">Token</label>
                </slot>
                <el-input
                  placeholder=""
                  type="textarea"
                  class=""
                  v-model="form.token"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                ></el-input>
              </el-form-item>
            </b-col>
          </b-row>

          <b-row>
            <b-col xs="12" sm="12">
              <ButtonPrimaryComponent
                class="float-right mt-4"
                title="Guardar"
                @onSaveClick="handleSaveClick"
              />
            </b-col>
          </b-row>
        </el-form>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Validate from "@/assets/validate";
import ButtonPrimaryComponent from "@/modules/dashboard/components/ButtonPrimary";

import tradeService from "../../../services/tradeService";

export default {
  name: "PaymentAdd",
  data() {
    return {
      form: {
        business_id: "",
        token: ""
      },
      rules: {
        token: [Validate.rules.required()]
      }
    };
  },
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    }
  },
  watch: {},
  methods: {
    async getIntegrationsPayment() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        let response = await tradeService
          .getIntegrationsPayment({
            params: {
              business_id: this.getUser.user_business.id
            }
          })
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, data } = response.data;
        if (success) {
          this.form = {
            ...this.form,
            ...data
          };
        }
      } catch (error) {
        return false;
      }
    },
    handleSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            this.form.business_id = this.getUser.user_business.id;
            let response = await tradeService
              .addIntegrationsPayment(this.form)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });
            const { success, message } = response.data;
            if (success) {
              this.$notifications.success({
                message
              });
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    async handleSinconizar() {
      this.$store.dispatch("toggleRequestLoading");
      try {
        this.form.business_id = this.getUser.user_business.id;
        let response = await tradeService
          .SyncIntegrationsWoocommerce({})
          .finally(() => {
            this.$store.dispatch("toggleRequestLoading");
          });
        const { success, message } = response.data;
        if (success) {
          this.$notifications.success({
            message
          });
        }
      } catch (error) {
        return false;
      }
    },
    onChangeSelectEmissionPoints({ emissionPointId }) {
      this.form.woocommerce_emision_point = emissionPointId;
    },
    onChangeDatePicker({ date }) {
      this.form.woocommerce_sync_date = date;
    }
  },
  components: {
    ButtonPrimaryComponent
    // MoreInfoComponent,
    // SelectEmissionPointsComponent,
    // InputDatePickerComponent,
    // ButtonComponent
  },
  props: {},
  mounted() {
    this.getIntegrationsPayment();
  }
};
</script>

<style lang="scss" scoped></style>
